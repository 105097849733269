<template>
  <div class="auth-area frmresetpasswd" id="reset-password" v-cloak>
    <div class="login-area">
      <form @submit.prevent="resetPassword" novalidate>
        <div class="register-form">
          <auth-form-progress v-if="request_send"></auth-form-progress>

          <div class="w-100 register-body">
            <div class="register-left-side">
              <auth-form-header :login="false" class="register-form-header">
                <template v-slot:header-label>Set new password</template>
                <template v-slot:sub-header-label
                  >Enter new password below</template
                >
              </auth-form-header>

              <div class="register-form-body">
                <new-password
                  :credentials="credentials"
                  :errors-form="errorsForm"
                ></new-password>
                <suspended-alert v-if="errorsForm.tokenInvalid">
                  <template slot="message">{{ errorsForm.message }}</template>
                </suspended-alert>

                <div class="row login-body-footer">
                  <!-- NEMESIS CHANGE <div class="form-group row register-control-padding-r"> -->
                  <div class="col-xs-6 create-account-link">
                    <router-link :disabled="request_send" to="/login/1"
                       v-if="resetBy=='token'">Return to Sign in</router-link>
                    <router-link :disabled="request_send" v-if="resetBy=='user_token'" to="/login/1"
                      >Keep my current password</router-link>
                  </div>

                  <div class="col-xs-6 text-right">
                    <button
                      :disabled="request_send"
                      class="btn btn-primary"
                      type="submit"
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <register-right
              :main-class="{ 'right-min-height': true }"
            ></register-right>
          </div>
        </div>

        <support-link></support-link>
      </form>
    </div>

    <auth-footer></auth-footer>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

import AuthFormProgress from "./Items/AuthFormProgress";
import AuthFooter from "./Items/AuthFooter";
import SupportLink from "./Items/SupportLink";
import RegisterRight from "./Items/RegisterRight";
import NewPassword from "./Items/NewPassword";
import AuthFormHeader from "./Items/AuthFormHeader";
import SuspendedAlert from "./Items/SuspendedAlert";

export default {
  name: "ResetPassword",
  components: {
    SuspendedAlert,
    AuthFormHeader,
    NewPassword,
    RegisterRight,
    SupportLink,
    AuthFooter,
    AuthFormProgress,
  },
  props: {
    resetBy: {
      type: String,
      default: "token",
    },
    user_token: {
      type: String,
      default: 0,
    },
  },
  data: () => ({
    request_send: true,
    credentials: {
      password: "",
      password_confirmation: "",
      token: "",
    },
    errorsForm: {
      message: "Token is invalid",
      tokenInvalid: false,
      password:
        "Use 8 characters or more with a mix of letters, numbers & symbols.",
      password_confirmation: "Password does not match.",
      passwordInvalid: false,
      passwordConfirmInvalid: false,
      passwordType: {
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimum: false,
      },
    },
  }),
  mounted() {
    this.request_send = false;
    this.credentials.token = this.$route.params.token;
  },
  methods: {
    ...mapMutations({
      setAccessToken: "setAccessToken",
      setUserInfo: "setUserInfo",
      destroyAccessToken: "destroyAccessToken",
    }),

    ...mapActions({
      resetPasswordByToken: "resetPasswordByToken",
    }),

    resetPassword() {
      console.log(this.$store.state);
      this.clearValidation();

      if (this.token === "" || this.token === null) {
        this.errorsForm.tokenInvalid = true;

        return false;
      }

      let valid = true;

      if (!this.validatePassword()) {
        this.errorsForm.passwordInvalid = true;

        valid = false;
      }

      if (
        this.credentials.password !== this.credentials.password_confirmation
      ) {
        this.errorsForm.passwordConfirmInvalid = true;
        valid = false;
      }

      if (!valid) {
        return false;
      }
      if (this.resetBy == "token") {
        this.resetByToken();
      } else if (this.resetBy == "user_token") {
        this.resetByUser();
      }
    },
    resetByUser() {
      this.axios({
        method: "post",
        url: `/reset_user_password/${this.user_token}`,
        params: this.user,
      })
        .then((response) => {
          let data = response.data;
          console.log(data);

  this.$router.push(`/login`);

          // this.setUserInfo(data.userInfo);

          // window.BCookie.set("BC-Auth-Email", data.email, 259200);

          // const { access_token: accessToken, expires_in: tokenExpired } = data;
          
          // this.setAccessToken({
          //   token: accessToken,
          //   expires: tokenExpired,
          // });
          //  console.log(data.userInfo.billing_type)
          // if (data.userInfo.user_plan_id != "free" && data.userInfo.billing_type!='by_admin') {
          //   this.$router.push(`/subscription/${data.userInfo.user_plan_id}`);
          //   this.$forceUpdate();
          // } else {
          //   console.log('ggg')
          //   this.$router.push("/");
          //   this.$forceUpdate();
          // }

          //this.$router.push("/subscription");
          //this.$router.push(`/subscription/${data.subscription.stripe_plan}`);
          //this.$forceUpdate();
        })
        .catch((error) => {
          //this.$router.push("/subscription");
        });
    },
    resetByToken() {
      this.resetPasswordByToken({ ...this.credentials })
        .then((r) => {
          if (r.data.result === "success") {
            // this.setUserInfo(r.data.userInfo);

            // window.BCookie.set("BC-Auth-Email", r.data.email, 259200);

            // const {
            //   access_token: accessToken,
            //   expires_in: tokenExpired,
            // } = r.data;

            // this.setAccessToken({
            //   token: accessToken,
            //   expires: tokenExpired,
            // });

            // this.$notify({
            //     title: "Password reset",
            //     message: "New password set for your account!",
            //     type: "success"
            // });

            this.$router.push("/login");
            this.$forceUpdate();
          } else {
            this.errorsForm.message =
              "Invalid token. Please re-send email and get link again.";
            this.errorsForm.tokenInvalid = true;
            this.destroyAccessToken();
          }
        })
        .catch((e) => {
          this.errorsForm.message =
            "Invalid token. Please re-send email and get link again.";
          this.errorsForm.tokenInvalid = true;
          this.destroyAccessToken();
        });
    },
    clearValidation() {
      this.errorsForm.message = "Token is invalid";
      this.errorsForm.tokenInvalid = false;
      this.errorsForm.passwordInvalid = false;
      this.errorsForm.passwordConfirmInvalid = false;
      this.errorsForm.passwordType = {
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimum: false,
      };
    },
    validatePassword() {
      window.vEvent.fire("password-validation", this.credentials.password);

      let re = /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{8,}$/;
      return re.test(String(this.credentials.password));
    },
  },
};
</script>

<style lang="less" src="../../assets/less/custom/pages.less"></style>
<style lang="less" src="../../assets/less/custom/auth.less"></style>
